<style lang="scss">
@import "~element-plus/dist/index.css";
@import '~element-plus/theme-chalk/dark/css-vars.css';
@import "~bootstrap-icons/font/bootstrap-icons.css";
@import "~apexcharts/dist/apexcharts.css";
@import "~quill/dist/quill.snow.css";
@import "~animate.css";
@import "~nouislider/dist/nouislider.min.css";
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~socicon/css/socicon.css";
@import "~line-awesome/dist/line-awesome/css/line-awesome.css";
@import "~dropzone/dist/dropzone.css";
@import "~@vueform/multiselect/themes/default.css";
@import "~prism-themes/themes/prism-shades-of-purple.css";
@import "~vue2-animate/dist/vue2-animate.min.css";
@import "~sweetalert2/dist/sweetalert2.min.css";
@import '~vue-simple-context-menu/dist/vue-simple-context-menu.css';
//@import '@sweetalert2/theme-borderless/borderless.scss';
//@import '@sweetalert2/theme-minimal/minimal.scss';

// Main Virtualoffice style scss
@import "assets/sass/plugins";
@import "assets/sass/style";
@import "@theme/style";
</style>

<script lang="ts">
import {defineComponent, onUpdated, provide, ref, watch} from "vue";
import {useI18n} from 'vue-i18n';
import utilsService from "@/core/services/utilsService";
import store from "@/store";
import {Actions} from "@/store/enums/store.enums";

// ENV VARS //
const theme = process.env.VUE_APP_THEME;

export default defineComponent({
  name: "vo-desk",
  setup() {

    // locale as key to force rerender ALL router views //
    const {locale} = useI18n({useScope: 'global'});
    let selectedLanguage = locale.value;

    // create global provider //
    //provide('translate', translate);

    //Stop error resizeObserver
    // https://github.com/vuejs/vue-cli/issues/7431
    const debounce = (callback: (...args: any[]) => void, delay: number) => {
      let tid: any;
      return function (...args: any[]) {
        const ctx = self;
        tid && clearTimeout(tid);
        tid = setTimeout(() => {
          callback.apply(ctx, args);
        }, delay);
      };
    };

    const _ = (window as any).ResizeObserver;
    (window as any).ResizeObserver = class ResizeObserver extends _ {
      constructor(callback: (...args: any[]) => void) {
        callback = debounce(callback, 20);
        super(callback);
      }
    };

    // set theme to body class //
    store.dispatch(Actions.ADD_BODY_CLASSNAME, theme);

    onUpdated(() => {

      // remember new select value in local storage //
      if (selectedLanguage !== locale.value) {

        selectedLanguage = locale.value;
        utilsService.setLocalStorage('user-language', selectedLanguage);

      }

    });

    return {
      locale
    }

  }

});
</script>

<template>
  <router-view :key="locale"/>
</template>
